<template>
  <div class="location-option" @click="handleClick" :style="{ pointerEvents: allValuesNull && nullDisabled ? 'none' : 'auto' }">
    <div class="location-content">
      <div class="location-name" :style="{ color: locationVariant }">{{ source.name }}</div>
      <small v-if="getGroup && getGroup.length !== 0" class="text-secondary">{{getGroup.join(', ')}}</small>
    </div>
    <b-form-checkbox v-show="mode === 'multiple'" :checked="locationSelected.some((l) => l.datasource === datasourceSelected && l.item === itemSelected && l.location === source.name) ? ['A'] : []" value="A" disabled></b-form-checkbox>
    <b-form-radio v-show="mode === 'single'" :checked="locationSelected.some((l) => l.datasource === datasourceSelected && l.item === itemSelected && l.location === source.name) ? 'B' : ''" value="B" disabled></b-form-radio>
  </div>
</template>

<script>
export default {
  props: ['index', 'source', 'mode', 'datasourceSelected', 'itemSelected', 'locationSelected', 'selectLocation', 'nullDisabled'],
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries')
    },
    allValuesNull() {
      if (!this.$db[this.datasourceSelected].columns[`${this.source.name}*${this.itemSelected}`]) return
      return this.$db[this.datasourceSelected].columns[`${this.source.name}*${this.itemSelected}`].nullCount === this.$db[this.datasourceSelected].dates.length
    },
    locationVariant() {
      if (!this.allValuesNull) {
        return 'inherit'
      } else {
        return '#ea5455' // danger
      }
    },
    getGroup() {
      const locationId = `${this.source.name}*${this.itemSelected}`
      const autoGroups = this.$db[this.datasourceSelected].autoGroups
      let result = []
      for (const group in autoGroups) {
        if (autoGroups[group].includes(locationId)) result.push(group)
      }
      return result
    }
  },
  methods: {
    handleClick() {
      this.selectLocation(this.source.name)
    },
  },
}
</script>

<style scoped>
.location-option {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.location-option:hover {
  background-color: #eeedfd;
}

.location-option.active {
  background-color: #d8dffd;
}

.dark-layout .location-option:hover {
  background-color: #31375a;
}

.dark-layout .location-option.active {
  background-color: #323f6e;
}

.location-option .location-content {
  flex-grow: 1;
  min-width: 0;
}

.location-option .location-content .location-name {
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
