const dayjs = require('dayjs');

export default {
  data() {
    return {
      flagAnnotations: null,
      flagComponent:null,
    };
  },
  computed: {
    chartAddYear() {
      return this.$store.state.settings.chartAddYear;
    },
    flagChartYear() {
      return `${this.chartAddYear}__${this.date}`;
    },
    date(){
      return `__${this.date}`;
    },
    annotations() {
      return this.flagAnnotations;
    },
    yearText() {
      return this.flagComponent;
    },
    
  },
  watch: {
    flagChartYear() {
      if (this.chartAddYear) {
        this.addYeartoLayout();
      } else {
        this.flagAnnotations = null;
      }
    },
    date(){
      this.flagComponent=this.getYearFromComponent()
    },
  },
  methods: {
    addYeartoLayout() {
      this.flagAnnotations = this.getYear(this.date);
    },
    getYear(date) {
      if (date.length === 1) {
        return dayjs(date[0]).format('YYYY');
      } else if (date.length === 2) {
        return `${dayjs(date[0]).format('YYYY')} - ${dayjs(date[1]).format('YYYY')}`;
      } else {
        return null;
      }
    },
    getYearFromComponent(date = this.date) {
      if (date.length === 1) {
        return dayjs(date[0]).format('YYYY');
      } else if (date.length === 2) {
        return `${dayjs(date[0]).format('YYYY')} - ${dayjs(date[1]).format('YYYY')}`;
      } else {
        return null;
      }
    },
  },
};
