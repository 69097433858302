<template>
  <div class="custom-input-container">
    <label class="toolbar-title text-primary">{{ $t(label) }}</label>
    <div class="input-wrapper">
      <b-form-input lazy @change="handleChange" :id="label" v-model="selected" :size="size || 'md'" :type="type || 'text'" :min="label === 'learning_rate' ? '0' : ''" :max="label === 'learning_rate' ? '1' : ''" :step="label === 'learning_rate' ? '0.001' : ''" :title="label === 'learning_rate' ? $t('title_learning_rate') : ''" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['type', 'value', 'size', 'label'],
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    selected() {
      this.$emit('input', this.selected);
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.selected);
      if (Number(document.querySelector(`#${this.label}`).value) === 0) {
        document.querySelector(`#${this.label}`).value = 0;
      }
      if (this.label === 'learning_rate') {
        if (Number(document.querySelector(`#${this.label}`).value) < 0) {
        document.querySelector(`#${this.label}`).value = 0;
        }
        if (Number(document.querySelector(`#${this.label}`).value) > Number(document.querySelector(`#${this.label}`).max)) {
          document.querySelector(`#${this.label}`).value = Number(document.querySelector(`#${this.label}`).max);
        }
      }
    },
  },
};
</script>

<style>
.custom-input-container .input-wrapper input:not(.form-control-sm) {
  border-radius: 0.357rem;
  padding: 8px;
  height: 42px;
}
</style>
