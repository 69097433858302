<template>
  <div class="empty-chart-container text-center">
    <div class="mb-3 empty-chart-title">
      <h2>{{ title || $t('datasource_empty') }}</h2>
      <i v-if="description">{{ description }}</i>
    </div>
    <div class="empty-chart-image" :style="{ backgroundImage: background }">
    </div>
  </div>
</template>

<script>
import { ThemeConfig } from '@/mixins/ThemeMixin'

const BACKGROUND_DARK = 'url(/images/background/chart-dark.png)'
const BACKGROUND_LIGHT = 'url(/images/background/chart-light.png)'

export default {
  props: ['title', 'description'],
  mixins: [ThemeConfig],
  computed: {
    background() {
      return this.isDark ? BACKGROUND_DARK : BACKGROUND_LIGHT
    },
  },
}
</script>

<style>
.empty-chart-container {
  position: absolute;
  left: 50%;
  top: 45%;
  width: 540px;
  transform: translate(-50%, -50%);
}
.empty-chart-container .empty-chart-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 256px;
  opacity: 0.75;
  margin: 0 10px;
}
@media (max-height: 680px) {
  .empty-chart-container .empty-chart-title {
    margin-bottom: 0 !important;
  }
}
@media (max-height: 920px) {
  .empty-chart-container .empty-chart-image {
    padding-top: 192px;
  }
}
</style>
