<template>
  <DynamicAnimate position="bottom-right">
    <b-media no-body>
      <b-media :title="$t('download')">
        <b-avatar rounded variant="light-primary" size="42">
          <feather-icon icon="DownloadIcon" size="18" />
        </b-avatar>
      </b-media>
    </b-media>
    <template #content>
      <ul class="dropdown-menu dropdown-menu-downloader">
        <li v-if="typeof downloadModel === 'function'" @click="downloadModel" :title="$t('download_model')">
          <a role="menuitem" href="#" target="_self" class="dropdown-item d-flex align-items-center">
            <feather-icon size="16" icon="CpuIcon" class="mr-50" />
            <span>{{ $t('model') }}</span>
          </a>
        </li>
        <li v-if="typeof downloadHtml === 'function'" @click="downloadHtml" :title="$t('download_html')">
          <a role="menuitem" href="#" target="_self" class="dropdown-item d-flex align-items-center">
            <feather-icon size="16" icon="GlobeIcon" class="mr-50" />
            <span>HTML</span>
          </a>
        </li>
        <li v-if="typeof downloadImage === 'function'" @click="downloadImage" :title="$t('download_image')">
          <a role="menuitem" href="#" target="_self" class="dropdown-item d-flex align-items-center">
            <feather-icon size="16" icon="ImageIcon" class="mr-50" />
            <span>{{ $t('image') }}</span>
          </a>
        </li>
        <li v-if="typeof downloadCsv === 'function'" @click="downloadCsv" :title="$t('download_csv')">
          <a role="menuitem" href="#" target="_self" class="dropdown-item d-flex align-items-center">
            <feather-icon size="16" icon="FileTextIcon" class="mr-50" />
            <span>{{ label === 'correlation' ? 'XLSX' : 'CSV' }}</span>
          </a>
        </li>
      </ul>
    </template>
  </DynamicAnimate>
</template>

<script>
import DynamicAnimate from './DynamicAnimate.vue';

export default {
  props: ['downloadModel', 'downloadHtml', 'downloadImage', 'downloadCsv', 'label'],
  components: { DynamicAnimate },
  methods: {
    download({ data, filename }) {
      if (!data) return;
      const a = document.createElement('a');
      a.href = data;
      a.download = filename;
      a.click();
    },
  },
};
</script>

<style>
.downloader-container {
  z-index: 11;
}
.dropdown-menu-downloader {
  right: -50px;
  left: auto;
}
</style>
