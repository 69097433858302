export default {
  computed: {
    datasourceSelected: {
      get() {
        return this.$store.state.tabs[this.$options.name].datasourceSelected;
      },
      set(datasourceSelected) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { datasourceSelected });
      },
    },
    datasources() {
      return this.$store.state.datasource.datasources;
    },
    selectedDatasources() {
      let datasources = this.$store.state.datasource.datasources;
      let selectedIDs = this.datasourceSelected.map((o) => o.datasource);
      return datasources.filter((o) => selectedIDs.includes(o.id)).map((datasource) => ({ ...datasource, ...this.$db[datasource.id] }));
    },
    // for DateMixin to create date array
    dataSelected() {
      return [...new Set(this.datasourceSelected.map((l) => l.datasource))];
    },
  },
};
