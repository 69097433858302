<template>
  <b-badge title="" class="custom-tooltip" :id="tooltipID" :variant="variant" pill :style="{color: isDark ? `#fff !important` : `#397bff !important` }">
    <span>?</span>
    <b-tooltip triggers="hover" :target="tooltipID" :title="title" :placement="placement" />
  </b-badge>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { ThemeConfig } from '@/mixins/ThemeMixin.js'

export default {
  props: {
    variant: {
      type: String,
      default: () => 'light-primary',
    },
    title: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: () => 'top',
    },
  },
  mixins: [ThemeConfig],
  data() {
    return {
      tooltipID: '',
    }
  },
  mounted() {
    this.tooltipID = uuidv4()
  },
}
</script>

<style>
.custom-tooltip {
  width: 18px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 4px 4px 4px 4px;
  color: white !important;
  margin: 0 4px;
}
.custom-tooltip:hover {
  box-shadow: 0px 0px 1px 1px rgb(57 123 255 / 0.5);
}
.b-tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: rgba(22, 29, 49, 0.8);
  backdrop-filter: blur(0px);
}
.b-tooltip.bs-tooltip-bottom:hover .arrow:before {
  border-bottom-color: rgba(22, 29, 49, 1);
  backdrop-filter: blur(4px);
}
.b-tooltip.bs-tooltip-top .arrow:before {
  border-top-color: rgba(22, 29, 49, 0.8);
  backdrop-filter: blur(0px);
}
.b-tooltip.bs-tooltip-top:hover .arrow:before {
  border-top-color: rgba(22, 29, 49, 1);
  backdrop-filter: blur(4px);
}
.b-tooltip.bs-tooltip-right .arrow:before {
  border-right-color: rgba(22, 29, 49, 0.8);
  backdrop-filter: blur(0px);
}
.b-tooltip.bs-tooltip-right:hover .arrow:before {
  border-right-color: rgba(22, 29, 49, 1);
  backdrop-filter: blur(4px);
}

.b-tooltip.bs-tooltip-left .arrow:before {
  border-left-color: rgba(22, 29, 49, 0.8);
  backdrop-filter: blur(0px);
}
.b-tooltip.bs-tooltip-left:hover .arrow:before {
  border-left-color: rgba(22, 29, 49, 1);
  backdrop-filter: blur(4px);
}

.b-tooltip .tooltip-inner {
  background-color: rgba(22, 29, 49, 0.8);
  backdrop-filter: blur(0px);
  text-align: left;
}
.b-tooltip:hover .tooltip-inner {
  background-color: rgba(22, 29, 49, 1);
  backdrop-filter: blur(4px);
}
</style>
