export default {
  beforeRouteLeave(to, from, next) {
    if (this.$refs.timeplayer) {
      this.$refs.timeplayer.pause();
    } else {
      console.log('Cannot find player to pause.');
    }
    next();
  },
}
