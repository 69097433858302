import Plotly from 'plotly.js-dist'
import FileSaver from 'file-saver'
// const { remote } = require('electron')
// const { dialog, getCurrentWindow } = remote

const config = {
  displaylogo: false,
  responsive: true,
  editable: true,
  modeBarButtonsToRemove: ['toImage'],
}

const TEMPLATE = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>{{TITLE}}</title>
    <style>
      body {
        margin: 0;
        padding: 20px;
        background-color: {{BACKGROUND}};
      }
      #plotly-chart {
        height: calc(100vh - 40px);
      }
    </style>
  </head>
  <body>
    <div id="plotly-chart"></div>
	<script src="https://cdn.plot.ly/plotly-2.6.2.min.js"></script>
    <script>
      document.addEventListener('DOMContentLoaded', function(event) {
        let el = document.getElementById('plotly-chart')
        Plotly.newPlot(el, {{DATA}}, {{LAYOUT}}, {{CONFIG}})
      })
    </script>
  </body>
</html>
`

export default {
  computed: {
    background() {
      let skin = this.$store.state.appConfig.layout.skin
      return skin === 'dark' ? '#283046' : '#ffffff'
    },
  },
  methods: {
    asImage(filename, chart = null) {
      if (chart === null) chart = this.$refs.chart
      if (!chart) return

      Plotly.downloadImage(chart, { filename })
    },
    asHtml(filename, chart = null) {
      if (chart === null) chart = this.$refs.chart
      if (!chart) return
      let { data, layout } = chart

      let template = TEMPLATE
      template = template.replace('{{TITLE}}', filename)
      template = template.replace('{{BACKGROUND}}', this.background)
      template = template.replace('{{DATA}}', JSON.stringify(data))
      template = template.replace('{{LAYOUT}}', JSON.stringify(layout))
      template = template.replace('{{CONFIG}}', JSON.stringify(config))

      let blob = new Blob([template], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(blob, `${filename}.html`)
    },
    asLogs(filename, data = null) {
      let blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(blob, `${filename}.log`)
    },
    asJson(filename, data = null) {
      let blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(blob, `${filename}.json`)
    },
    asCSV(filename, data = null) {
      let blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(blob, `${filename}.csv`)
    },
    writeFile(filePath, data) {
      try {
        fs.writeFileSync(filePath, data, 'utf8')
      } catch (error) {
        console.error(error)
      }
    },
  },
}
