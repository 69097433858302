<template>
  <div class="chart-year-text-container" ref="refCharText">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: ['text'],
};
</script>

<style scoped>
.chart-year-text-container {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  position: absolute;
  font-size: 8rem;
  opacity: 0.5;
  top: 50%;
  left: 50% ;
  transform: translate(-50%, -50%);
  z-index: 0;
  pointer-events: none;
}
</style>
