export const locale = {
  moduleType: 'locale',
  name: 'ja',
  dictionary: {
    Autoscale: '\u81ea\u52d5\u30ba\u30fc\u30e0',
    'Box Select': '\u77e9\u5f62\u9078\u629e',
    'Click to enter Colorscale title': '\u8272\u30b9\u30b1\u30fc\u30eb\u30bf\u30a4\u30c8\u30eb\u3092\u5165\u529b\u3059\u308b\u306b\u306f\u30af\u30ea\u30c3\u30af',
    'Click to enter Component A title': '\u30b3\u30f3\u30dd\u30fc\u30cd\u30f3\u30c8A\u306e\u30bf\u30a4\u30c8\u30eb\u5165\u529b\u3059\u308b\u306b\u306f\u30af\u30ea\u30c3\u30af',
    'Click to enter Component B title': '\u30b3\u30f3\u30dd\u30fc\u30cd\u30f3\u30c8B\u306e\u30bf\u30a4\u30c8\u30eb\u5165\u529b\u3059\u308b\u306b\u306f\u30af\u30ea\u30c3\u30af',
    'Click to enter Component C title': '\u30b3\u30f3\u30dd\u30fc\u30cd\u30f3\u30c8C\u306e\u30bf\u30a4\u30c8\u30eb\u5165\u529b\u3059\u308b\u306b\u306f\u30af\u30ea\u30c3\u30af',
    'Click to enter Plot title': '\u30d7\u30ed\u30c3\u30c8\u30bf\u30a4\u30c8\u30eb\u3092\u5165\u529b\u3059\u308b\u306b\u306f\u30af\u30ea\u30c3\u30af',
    'Click to enter X axis title': 'X\u8ef8\u30bf\u30a4\u30c8\u30eb\u3092\u5165\u529b\u3059\u308b\u306b\u306f\u30af\u30ea\u30c3\u30af',
    'Click to enter Y axis title': 'Y\u8ef8\u30bf\u30a4\u30c8\u30eb\u3092\u5165\u529b\u3059\u308b\u306b\u306f\u30af\u30ea\u30c3\u30af',
    'Compare data on hover': '\u30db\u30d0\u30fc\u4e2d\u306e\u30c7\u30fc\u30bf\u3092\u6bd4\u8f03',
    'Double-click on legend to isolate one trace': '\u51e1\u4f8b\u3092\u30c0\u30d6\u30eb\u30af\u30ea\u30c3\u30af\u3057\u30661\u3064\u306e\u30c8\u30ec\u30fc\u30b9\u3092\u7121\u52b9\u5316\u3057\u307e\u3059',
    'Double-click to zoom back out': '\u30c0\u30d6\u30eb\u30af\u30ea\u30c3\u30af\u3067\u30ba\u30fc\u30e0\u3092\u623b\u3057\u307e\u3059',
    'Download plot as a png': 'PNG\u30d5\u30a1\u30a4\u30eb\u3067\u30c0\u30a6\u30f3\u30ed\u30fc\u30c9',
    'Download plot': '\u30c0\u30a6\u30f3\u30ed\u30fc\u30c9',
    'Edit in Chart Studio': 'Chart Studio\u3067\u7de8\u96c6',
    'IE only supports svg.  Changing format to svg.': 'IE\u306fSVG\u306e\u307f\u30b5\u30dd\u30fc\u30c8\u3057\u3066\u3044\u307e\u3059\u3002SVG\u306b\u5909\u63db\u3057\u307e\u3059\u3002',
    'Lasso Select': '\u6295\u3052\u7e04\u9078\u629e',
    'Orbital rotation': '\u8ecc\u9053\u56de\u8ee2',
    Pan: '\u62e1\u5927',
    'Produced with Plotly.js': 'Plotly.js\u306b\u3088\u308a\u4f5c\u6210',
    Reset: '\u30ea\u30bb\u30c3\u30c8',
    'Reset axes': '\u8ef8\u3092\u30ea\u30bb\u30c3\u30c8',
    'Reset camera to default': '\u30ab\u30e1\u30e9\u3092\u30c7\u30d5\u30a9\u30eb\u30c8\u306b\u623b\u3059',
    'Reset camera to last save': '\u30ab\u30e1\u30e9\u3092\u6700\u5f8c\u306e\u4fdd\u5b58\u72b6\u614b\u306b\u623b\u3059',
    'Reset view': '\u30d3\u30e5\u30fc\u3092\u30ea\u30bb\u30c3\u30c8',
    'Reset views': '\u30d3\u30e5\u30fc\u3092\u30ea\u30bb\u30c3\u30c8',
    'Show closest data on hover': '\u30db\u30d0\u30fc\u6642\u306b\u4e00\u756a\u8fd1\u3044\u30c7\u30fc\u30bf\u3092\u8868\u793a',
    'Snapshot succeeded': '\u30b9\u30ca\u30c3\u30d7\u30b7\u30e7\u30c3\u30c8\u306b\u6210\u529f',
    'Sorry, there was a problem downloading your snapshot!': '\u3059\u307f\u307e\u305b\u3093\u3001\u30b9\u30ca\u30c3\u30d7\u30b7\u30e7\u30c3\u30c8\u30c0\u30a6\u30f3\u30ed\u30fc\u30c9\u3067\u30a8\u30e9\u30fc\u3067\u3059!',
    'Taking snapshot - this may take a few seconds': '\u30b9\u30ca\u30c3\u30d7\u30b7\u30e7\u30c3\u30c8\u3092\u64ae\u5f71',
    Zoom: '\u30ba\u30fc\u30e0',
    'Zoom in': '\u62e1\u5927',
    'Zoom out': '\u7e2e\u5c0f',
    'close:': '\u9589\u3058\u308b:',
    trace: '\u30c8\u30ec\u30fc\u30b9:',
    'lat:': '\u7def\u5ea6:',
    'lon:': '\u7d4c\u5ea6:',
    'q1:': '\u7b2c\u4e00\u56db\u5206\u4f4d\u6570:',
    'q3:': '\u7b2c\u4e09\u56db\u5206\u4f4d\u6570:',
    'source:': '\u30bd\u30fc\u30b9:',
    'target:': '\u30bf\u30fc\u30b2\u30c3\u30c8:',
    'lower fence:': '\u30d5\u30a7\u30f3\u30b9\u4e0b\u9650:',
    'upper fence:': '\u30d5\u30a7\u30f3\u30b9\u4e0a\u9650:',
    'max:': '\u6700\u5927:',
    'mean \xb1 \u03c3:': '\u5e73\u5747 \xb1 \u03c3:',
    'mean:': '\u5e73\u5747:',
    'median:': '\u4e2d\u592e\u5024:',
    'min:': '\u6700\u5c0f:',
    'Turntable rotation': '\u8ef8\u56de\u8ee2:',
    'Toggle Spike Lines': '\u30b9\u30d1\u30a4\u30af\u30e9\u30a4\u30f3\u306e\u30aa\u30f3\u30aa\u30d5',
    'open:': '\u958b\u304f:',
    'high:': '\u9ad8:',
    'low:': '\u4f4e:',
    'Toggle show closest data on hover': '\u30db\u30d0\u30fc\u6642\u306b\u4e00\u756a\u8fd1\u3044\u30c7\u30fc\u30bf\u3092\u8868\u793a\u306e\u30aa\u30f3\u30aa\u30d5',
    'incoming flow count:': '\u6d41\u5165\u30d5\u30ed\u30fc\u30ab\u30a6\u30f3\u30c8:',
    'outgoing flow count:': '\u6d41\u51fa\u30d5\u30ed\u30fc\u30ab\u30a6\u30f3\u30c8:',
    'kde:': 'kde:',
    'Click to enter radial axis title': '\u653e\u5c04\u8ef8\u30bf\u30a4\u30c8\u30eb\u3092\u5165\u529b\u3059\u308b\u306b\u306f\u30af\u30ea\u30c3\u30af',
    'new text': '\u65b0\u898f\u30c6\u30ad\u30b9\u30c8',
  },
  format: { days: ['\u65e5\u66dc\u65e5', '\u6708\u66dc\u65e5', '\u706b\u66dc\u65e5', '\u6c34\u66dc\u65e5', '\u6728\u66dc\u65e5', '\u91d1\u66dc\u65e5', '\u571f\u66dc\u65e5'], shortDays: ['\u65e5', '\u6708', '\u706b', '\u6c34', '\u6728', '\u91d1', '\u571f'], months: ['1\u6708', '2\u6708', '3\u6708', '4\u6708', '5\u6708', '6\u6708', '7\u6708', '8\u6708', '9\u6708', '10\u6708', '11\u6708', '12\u6708'], shortMonths: ['1\u6708', '2\u6708', '3\u6708', '4\u6708', '5\u6708', '6\u6708', '7\u6708', '8\u6708', '9\u6708', '10\u6708', '11\u6708', '12\u6708'], date: '%Y/%m/%d' },
};
