<template>
  <div class="d-flex summary-data-container">
    <div class="summary-counter d-flex mr-2 box-Toolbar-scroll">
      <b-media no-body v-for="item in summaryItems" :key="item.icon" style="margin-right: 10px" :class="{ 'is-na': item.isNa }" @click="handleClick(item)">
        <b-media-aside class="mr-1">
          <b-avatar size="38" :variant="item.color" :title="$t(item.toolTip == 'tooltipNA' ? $t('show_％_count') : item.toolTip)">
            <feather-icon size="24" :icon="item.icon" />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto position-relative">
          <span class="s-sub" v-if="String(item.title).startsWith('-')">-</span>
          <h5 class="font-weight-bolder mb-0" style="min-width: 51px">
            {{ String(item.title).startsWith('-') ? item.title.slice(1) : item.title }}
          </h5>
          <span class="font-small-2 mb-0 text-nowrap text-uppercase">
            {{ item.subtitle }}
          </span>
        </b-media-body>
      </b-media>
    </div>
    <div class="summary-slider d-flex">
      <span v-for="colum in showIdSummaryData" class="summary-slider-span">
        <div v-for="index in colum" :title="`${summarySlider.tooltips[index]}`" class="summary-slider-items badge badge-light-primary" :class="{ 'text-left': chartData2SummaryData.length > 1 }">
          <span class="summary-slider-items-text" :class="{ 'text-left': chartData2SummaryData.length > 1 }">{{ chartData2SummaryData.length > 1 ? `${summarySlider.tooltips[index]}${index == 5 ? ` :` : `:`}` : `${summarySlider.tooltips[index]}` }}</span>
          <span class="summary-slider-items-num">{{ chartData2SummaryData.length > 1 ? `${summarySlider.data[summarySlider.tooltips[index]]}` : `` }}</span>
        </div>
      </span>
    </div>
    <div class="summary-mobile">
      <div class="summary-counter-mobile d-none">
        <div class="summary-counter-button">
          <DynamicAnimate position="bottom-right">
            <b-media-aside :title="$t('click_to_show_summary')">
              <b-avatar size="28" variant="light-primary">
                <feather-icon size="18" icon="ServerIcon" />
              </b-avatar>
            </b-media-aside>
            <template #content>
              <div class="summary-background-wrapper summary-counter-wrapper">
                <div class="summary-background-content">
                  <b-media no-body v-for="item in summaryItems" :key="item.icon" :class="{ 'is-na click-not-close': item.isNa }" @click="handleClick(item)">
                    <b-media-aside class="mr-1">
                      <b-avatar size="38" :variant="item.color" :title="$t(item.toolTip == 'tooltipNA' ? $t('show_％_count') : item.toolTip)">
                        <feather-icon size="24" :icon="item.icon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto position-relative">
                      <span class="s-sub" v-if="String(item.title).startsWith('-')">-</span>
                      <h5 class="font-weight-bolder mb-0" style="min-width: 51px">
                        {{ String(item.title).startsWith('-') ? item.title.slice(1) : item.title }}
                      </h5>
                      <span class="font-small-2 mb-0 text-nowrap text-uppercase">
                        {{ item.subtitle }}
                      </span>
                    </b-media-body>
                  </b-media>
                </div>
              </div>
            </template>
          </DynamicAnimate>
        </div>
      </div>
      <!-- <div class="summary-slider-mobile d-none"> -->
      <div class="summary-slider-mobile">
        <div class="summary-slider-button">
          <DynamicAnimate position="bottom-right">
            <b-media :title="$t('click_to_show_summary')">
              <b-avatar size="28" variant="light-primary">
                <feather-icon size="18" icon="CircleIcon" />
              </b-avatar>
            </b-media>
            <template #content>
              <div class="summary-background-wrapper summary-slider-wrapper">
                <div class="summary-background-content">
                  <div class="summary-slider-mobile-content">
                    <span v-for="colum in showIdSummaryData" class="summary-slider-span">
                      <div v-for="index in colum" :title="`${summarySlider.tooltips[index]}`" class="summary-slider-items badge badge-light-primary" :class="{ 'text-left': chartData2SummaryData.length > 1 }">
                        <span class="summary-slider-items-text" :class="{ 'text-left': chartData2SummaryData.length > 1 }">{{ chartData2SummaryData.length > 1 ? `${summarySlider.tooltips[index]}${index == 5 ? ` :` : `:`}` : `${summarySlider.tooltips[index]}` }}</span>
                        <span class="summary-slider-items-num">{{ chartData2SummaryData.length > 1 ? `${summarySlider.data[summarySlider.tooltips[index]]}` : `` }}</span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </template>
          </DynamicAnimate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as math from 'mathjs'
import VueSlider from 'vue-slider-component'
import MathMixin from '@/mixins/MathMixin.js'
import DynamicAnimate from './DynamicAnimate.vue'

const NA_DISPLAY_TYPES = {
  ORIGINAL: 'original',
  PERCENTAGE: 'pergentage',
}

export default {
  props: ['chartData', 'resampleCount'],
  components: { VueSlider, DynamicAnimate },
  mixins: [MathMixin],
  data() {
    return {
      naDisplayType: NA_DISPLAY_TYPES.ORIGINAL,
      tooltipNA: 'show_％_count',
      showIdSummaryData: [
        [0, 4],
        [1, 3],
        [2, 5],
      ],
    }
  },
  computed: {
    chartData2SummaryData() {
      return [...(this.chartData || [])]
    },
    summaryData() {
      let data = { min: null, q1: null, median: null, mean: null, q3: null, max: null, sum: null, totalCount: 0, average: null, naCount: 0, resampleCount: 0, std: 0 }
      try {
        const chartData = this.chartData2SummaryData.filter((o) => !this.isNaValue(o))
        const totalCount = this.chartData2SummaryData.length
        const naCount = totalCount >= chartData.length ? totalCount - chartData.length : 0
        const sum = chartData.reduce((partial_sum, a) => partial_sum + a, 0)
        data.totalCount = totalCount.toString()
        data.naCount = naCount.toString()
        const sumAverage = this.autoFormatNumbers([sum, totalCount ? sum / (totalCount - naCount) : 'NA'], { addComma: true })
        data.sum = sumAverage[0]
        data.average = sumAverage[1]
        let std = 0
        if (chartData.length > 1) {
          std = math.std(chartData)
        }
        if (chartData.length) {
          const dataSorted = this.sortData(chartData)
          const summaryData = this.autoFormatNumbers([this.getMin(dataSorted), this.getMax(dataSorted), this.getMean(dataSorted), this.getMedian(dataSorted), this.getFirstQuantile(dataSorted), this.getThirdQuantile(dataSorted), std], { addComma: true })
          data.min = summaryData[0]
          data.max = summaryData[1]
          data.mean = summaryData[2]
          data.median = summaryData[3]
          data.q1 = summaryData[4]
          data.q3 = summaryData[5]
          data.std = summaryData[6]
        }
        if (this.resampleCount) {
          data.resampleCount = this.autoFormatNumbers([this.resampleCount], { addComma: true })[0]
        }
      } catch {}
      return data
    },
    naValueDisplay() {
      if (this.naDisplayType === NA_DISPLAY_TYPES.ORIGINAL) {
        return parseFloat(this.summaryData.naCount).toLocaleString()
      } else {
        const totalCount = parseFloat(this.summaryData.totalCount)
        const naCount = parseFloat(this.summaryData.naCount)
        return !totalCount ? '0%' : `${parseFloat(((naCount / totalCount) * 100).toFixed(2))}%`
      }
    },
    summaryItems() {
      let totalCount = parseFloat(this.summaryData.totalCount).toLocaleString()
      return [
        { icon: 'FastForwardIcon', toolTip: '', color: 'light-primary', title: this.summaryData.resampleCount, subtitle: this.$i18n.t('resample_default'), customClass: 'mb-2 mb-xl-0' },
        { icon: 'DiscIcon', toolTip: '', color: 'light-primary', title: totalCount, subtitle: this.$i18n.t('count'), customClass: 'mb-2 mb-xl-0' },
        { icon: 'PlusCircleIcon', toolTip: '', color: 'light-primary', title: this.summaryData.sum, subtitle: this.$i18n.t('sum'), customClass: 'mb-2 mb-xl-0' },
        { icon: 'DivideCircleIcon', toolTip: '', color: 'light-primary', title: this.summaryData.average, subtitle: this.$i18n.t('avg.'), customClass: 'mb-2 mb-sm-0' },
        { icon: 'SlashIcon', toolTip: this.$i18n.t('show_％_count'), color: 'light-danger', title: this.naValueDisplay, subtitle: this.$i18n.t('NA'), isNa: true, customClass: '' },
      ]
    },
    summarySlider() {
      const min = this.summaryData.min !== null ? this.summaryData.min : this.$i18n.t('min')
      const q1 = this.summaryData.q1 !== null ? this.summaryData.q1 : 'Q1'
      const median = this.summaryData.median !== null ? this.summaryData.median : this.$i18n.t('median')
      const q3 = this.summaryData.q3 !== null ? this.summaryData.q3 : 'Q3'
      const max = this.summaryData.max !== null ? this.summaryData.max : this.$i18n.t('max')
      const std = this.summaryData.std !== null ? this.summaryData.std : this.$i18n.t('standard_deviation')
      const data = { MIN: min, Q1: q1, MED: median, Q3: q3, MAX: max, STD: std }
      const tooltips = ['MIN', 'Q1', 'MED', 'Q3', 'MAX', 'STD']
      return { data, tooltips }
    },
  },
  methods: {
    handleClick(item) {
      this.tooltipNA = this.$i18n.t('show_％_count')
      if (item.isNa) {
        this.naDisplayType = this.naDisplayType === NA_DISPLAY_TYPES.ORIGINAL ? NA_DISPLAY_TYPES.PERCENTAGE : NA_DISPLAY_TYPES.ORIGINAL
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss">
:root {
  --gap_left_items_summary: 4px;
  --gap_top_items_summary: 3px;
}
.box-Toolbar-scroll {
  max-width: 670px;
  overflow-x: auto;
}
.box-Toolbar-scroll:hover::-webkit-scrollbar-thumb {
  background: #888888;
}
.box-Toolbar-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.box-Toolbar-scroll::-webkit-scrollbar-corner {
  background: transparent;
}
.box-Toolbar-scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 2.5px;
}
.box-Toolbar-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.summary-data-container {
  margin-right: 20px;
  .summary-slider {
    max-width: 380px;
    margin-left: calc(0px - var(gap_left_items_summary));
    &-items {
      display: flex !important;
      justify-content: space-between;
      cursor: default;
      &:nth-child(2) {
        margin-top: var(--gap_top_items_summary);
      }
    }
    &-span {
      margin-left: var(--gap_left_items_summary);
    }
  }
  .vue-slider {
    margin-bottom: 10px !important;
  }
  .vue-slider-mark-label {
    font-size: 0.8rem;
  }
  .s-sub {
    position: absolute;
    left: -5px;
  }
  .is-na {
    cursor: pointer;
  }
  .feather.feather-fast-forward {
    transform: rotate(90deg);
  }
  .summary-slider-span {
    .summary-slider-items {
      font-weight: normal;
      &-text {
        min-width: 30px;
        display: inline-block;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 1200px) {
  .menu-hide .summary-data-container,
  .menu-open .summary-data-container {
    margin-right: 0 !important;
  }
  .menu-hide .summary-data-container .summary-counter,
  .menu-open .summary-data-container .summary-counter {
    margin-right: 0 !important;
  }
  .menu-hide .summary-data-container .summary-slider,
  .menu-open .summary-data-container .summary-slider {
    display: none !important;
  }
  .menu-hide .summary-data-container .summary-slider-mobile,
  .menu-open .summary-data-container .summary-slider-mobile {
    display: flex !important;
  }
  .menu-hide .summary-data-container .summary-mobile,
  .menu-open .summary-data-container .summary-mobile {
    display: flex;
  }
}
@media (max-width: 1200px) {
  .menu-hide .summary-data-container .summary-counter,
  .menu-open .summary-data-container .summary-counter {
    display: none !important;
  }
  .menu-hide .summary-data-container .summary-counter-mobile,
  .menu-open .summary-data-container .summary-counter-mobile {
    display: flex !important;
  }
}

//------------------TABS MAIN--------------------------

/* TIMESERIES, LINE, SCATTER, BOXPLOT, HISTOGRAM */
/* SLIDER */
@media (max-width: 1668px) {
  .menu-expanded,
  .menu-collapsed {
    .timeseries-card,
    .line-card,
    .scatter-card,
    .boxplot-card,
    .histogram-card {
      .summary-data-container {
        margin-right: 0 !important;
        .summary-counter {
          margin-right: 0 !important;
        }
        .summary-slider {
          display: none !important;
        }
        .summary-slider-mobile {
          display: flex !important;
        }
        .summary-mobile {
          display: flex;
        }
      }
    }
  }
}
/* COUNTER */
@media (max-width: 1442px) {
  .menu-expanded,
  .menu-collapsed {
    .timeseries-card,
    .line-card,
    .scatter-card,
    .boxplot-card,
    .histogram-card {
      .summary-data-container {
        .summary-counter {
          display: none !important;
        }
        .summary-counter-mobile {
          display: flex !important;
        }
      }
    }
  }
}

/* DECOMPOSITION */
/* SLIDER */
@media (max-width: 1668px) {
  .menu-collapsed,
  .menu-expanded {
    .decomposition-card,
    .seasonal-analytics-card {
      .summary-data-container {
        margin-right: 0 !important;
        .summary-counter {
          margin-right: 0 !important;
        }
        .summary-slider {
          display: none !important;
        }
        .summary-slider-mobile {
          display: flex !important;
        }
        .summary-mobile {
          display: flex;
        }
      }
    }
  }
}
/* COUNTER */
@media (max-width: 1442px) {
  .menu-collapsed,
  .menu-expanded {
    .decomposition-card,
    .seasonal-analytics-card {
      .summary-data-container {
        .summary-counter {
          display: none !important;
        }
        .summary-counter-mobile {
          display: flex !important;
        }
      }
    }
  }
}

/* CORRELATION */
/* SLIDER */
@media (max-width: 1668px) {
  .menu-collapsed,
  .menu-expanded {
    .correlation-card {
      .summary-data-container {
        margin-right: 0 !important;
        .summary-counter {
          margin-right: 0 !important;
        }
        .summary-slider {
          display: none !important;
        }
        .summary-slider-mobile {
          display: flex !important;
        }
        .summary-mobile {
          display: flex;
        }
      }
    }
  }
}
/* COUNTER */
@media (max-width: 1442px) {
  .menu-collapsed,
  .menu-expanded {
    .correlation-card {
      .summary-data-container {
        .summary-counter {
          display: none !important;
        }
        .summary-counter-mobile {
          display: flex !important;
        }
      }
    }
  }
}

//------------------TABS BETA UPLOT--------------------------
/* TIMESERIES UPLOT */
/* SLIDER */
@media (max-width: 2048px) {
  .menu-collapsed,
  .menu-expanded {
    .timeseries-uplot-card {
      .summary-data-container {
        margin-right: 0 !important;
        .summary-counter {
          margin-right: 0 !important;
        }
        .summary-slider {
          display: none !important;
        }
        .summary-slider-mobile {
          display: flex !important;
        }
        .summary-mobile {
          display: flex;
        }
      }
    }
  }
}
/* COUNTER */
@media (max-width: 1920px) {
  .menu-collapsed,
  .menu-expanded {
    .timeseries-uplot-card {
      .summary-data-container {
        .summary-counter {
          display: none !important;
        }
        .summary-counter-mobile {
          display: flex !important;
        }
      }
    }
  }
}

/* LINE UPLOT */
/* SLIDER */
@media (max-width: 2048px) {
  .menu-collapsed,
  .menu-expanded {
    .line-uplot-card {
      .summary-data-container {
        margin-right: 0 !important;
        .summary-counter {
          margin-right: 0 !important;
        }
        .summary-slider {
          display: none !important;
        }
        .summary-slider-mobile {
          display: flex !important;
        }
        .summary-mobile {
          display: flex;
        }
      }
    }
  }
}
/* COUNTER */
@media (max-width: 1920px) {
  .menu-collapsed,
  .menu-expanded {
    .line-uplot-card {
      .summary-data-container {
        .summary-counter {
          display: none !important;
        }
        .summary-counter-mobile {
          display: flex !important;
        }
      }
    }
  }
}
/* SCATTER THREE */
/* SLIDER */
@media (max-width: 1830px) {
  .menu-collapsed,
  .menu-expanded {
    .scatter-three-card {
      .summary-data-container {
        margin-right: 0 !important;
        .summary-counter {
          margin-right: 0 !important;
        }
        .summary-slider {
          display: none !important;
        }
        .summary-slider-mobile {
          display: flex !important;
        }
        .summary-mobile {
          display: flex;
        }
      }
    }
  }
}
/* COUNTER */
@media (max-width: 1740px) {
  .menu-collapsed,
  .menu-expanded {
    .scatter-three-card {
      .summary-data-container {
        .summary-counter {
          display: none !important;
        }
        .summary-counter-mobile {
          display: flex !important;
        }
      }
    }
  }
}

/* SCATTER UPLOT */
/* SLIDER */
@media (max-width: 1830px) {
  .menu-collapsed,
  .menu-expanded {
    .scatter-uplot-card {
      .summary-data-container {
        margin-right: 0 !important;
        .summary-counter {
          margin-right: 0 !important;
        }
        .summary-slider {
          display: none !important;
        }
        .summary-slider-mobile {
          display: flex !important;
        }
        .summary-mobile {
          display: flex;
        }
      }
    }
  }
}
/* COUNTER */
@media (max-width: 1740px) {
  .menu-collapsed,
  .menu-expanded {
    .scatter-uplot-card {
      .summary-data-container {
        .summary-counter {
          display: none !important;
        }
        .summary-counter-mobile {
          display: flex !important;
        }
      }
    }
  }
}

/* BOX PLOT */
/* SLIDER */
@media (max-width: 1830px) {
  .menu-collapsed,
  .menu-expanded {
    .boxplot-uplot-card {
      .summary-data-container {
        margin-right: 0 !important;
        .summary-counter {
          margin-right: 0 !important;
        }
        .summary-slider {
          display: none !important;
        }
        .summary-slider-mobile {
          display: flex !important;
        }
        .summary-mobile {
          display: flex;
        }
      }
    }
  }
}
/* COUNTER */
@media (max-width: 1740px) {
  .menu-collapsed,
  .menu-expanded {
    .boxplot-uplot-card {
      .summary-data-container {
        .summary-counter {
          display: none !important;
        }
        .summary-counter-mobile {
          display: flex !important;
        }
      }
    }
  }
}

/* HISTOGRAM UPLOT */
/* SLIDER */
@media (max-width: 2048px) {
  .menu-collapsed,
  .menu-expanded {
    .histogram-uplot-card {
      .summary-data-container {
        margin-right: 0 !important;
        .summary-counter {
          margin-right: 0 !important;
        }
        .summary-slider {
          display: none !important;
        }
        .summary-slider-mobile {
          display: flex !important;
        }
        .summary-mobile {
          display: flex;
        }
      }
    }
  }
}
/* COUNTER */
@media (max-width: 1920px) {
  .menu-collapsed,
  .menu-expanded {
    .histogram-uplot-card {
      .summary-data-container {
        .summary-counter {
          display: none !important;
        }
        .summary-counter-mobile {
          display: flex !important;
        }
      }
    }
  }
}
</style>
